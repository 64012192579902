.FullforProfilSide {
  & h1 {
    @include breakpoint-below-tablet {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 24px;
    align-items: baseline;

    @include breakpoint-below-tablet {
      grid-template-columns: 1fr;
      row-gap: 12px;
    }

    &-hoyre-redigerbar {
      padding-left: 0;
      width: 340px;

      @include breakpoint-hd {
        width: 360px;
      }
    }

    &__buttons {
      display: grid;
      grid-auto-flow: column;
      justify-content: end;
      align-items: center;
      column-gap: 15px;

      @include breakpoint-tablet {
        grid-column: span 2;
      }
    }

    & input {
      padding: 10px;
      font-size: 16px;
    }
  }
}
