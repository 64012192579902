.otherDocs{
    margin: 3em 0px;
    position: relative;

    .-heading{
        &--big{
            font-size: 2em;
            margin-bottom: 1em;
        }
    }
}