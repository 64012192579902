.TilbudteObjekter {
  ul {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 15px;
    }
  }

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  &--topSection{
    display: flex;
    justify-content: space-between;
    @include breakpoint-below-tablet{
      flex-direction: column;
    }
  }
}
