.item-header {
  &.-isOpen {
    .row--icon {
      .expandable-btn {
        transform: rotate(180deg);
      }
    }
  }
}

.item-content {
  overflow: hidden;
  height: 0;

  &.-isOpen {
    height: auto;
    position: relative;
    top: -1px;
  }
}
