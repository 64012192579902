.Table {
  &__headers {
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    text-align: left;

    p {
      color: #147E88;
    }
  }

  &__table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__empty {
    color: #cccccc;
    font-style: italic;
    text-align: center;
    margin: 2em;
  }

  &__pagination {
    margin-top: 30px;
    display: flex;

    &__text {
      display: inline-flex;
      align-items: center;
      margin-left: 30px;
    }

    & button {
      background: none;
      border: 1px solid lightgray;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #147E88;

      &.inactive {
        font-size: 20px;
        color: black;
      }

      &.active {
        font-size: 20px;
        background: lightgray;
      }
    }
  }

  &__cell {
    height: 28px;
    color: #232E3A;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px;
    max-width: 100px;

    &:first-child {
      max-width: 320px;
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__icons {
      display: flex;
      margin-bottom: 1em;

      button{
        border: unset;
      }

      a {
        font-size: 30px;
        color: black;
        cursor: pointer;

        &:hover {
          color: #147E88;
        }
      }
    }
  }

  @keyframes glowFade {
    0% {
      box-shadow: inset 0 0 10px $color--glassbla01;
    }

    60% {
      box-shadow: inset 0 0 20px $color--glassbla01;
    }

    100% {
      box-shadow: inset 0 0 0 transparent;
    }
  }

  #selected-row {
    border-radius: 3px;
    animation: 10s ease-out 0s 1 glowFade;
  }

  &__table {
    width: 100%;
    border-spacing: 16px 0;
  }
}
