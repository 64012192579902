.download-btn{
    height: var(--button-height);
    padding: 0.375em 0.75em;
    margin: 2em 0em;
    font-family: var(--gt-america);
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 22px;
    transition: all 0.3s ease 0s;
    background-image: none;
    box-shadow: none;
    border-radius: 0;
    background-color: #147e87;
    width: max-content;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    background-color: var(--button);
    color: var(--button-color);
    display: flex;
    justify-content: center;
    align-items: center;

  &:hover {
  background-color: var(--button-hover);
  color: var(--button-color);
  }

  span{
    margin-left: 10px;
  }
}