.UploadedFileLabel {
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  justify-items: flex-start;
  align-items: center;
  padding: 2rem 0;
  background-color: #d5eaee;
  min-height: 114px;
  margin-bottom: 2rem;

  &--meta {
    display: flex;
    flex-direction: column;

    >span {
      font-family: var(--gt-america);
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
    }

    &--bottom {
      margin-top: 5px;

      >span {
        font-size: 14px;

        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
  }

  &--file {
    justify-self: center;
  }

  &--trash {
    justify-self: center;
    background-color: transparent;
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &--download {
    justify-self: center;
    color: black;
  }
}
