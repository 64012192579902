.reccomendation-label {
  padding: 4px 14px;
  border-radius: 20px;
  font-size: 14px;

  &.-green {
    background-color: #b9df73;
  }

  &.-yellow {
    background-color: #faf600;
  }

  &.-red {
    background-color: #f7aeae;
  }

  .icon{
    margin-right: 5px;
  }
}
