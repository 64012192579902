@import '../../scss/vars.scss';

.DokumentSkjema {
  max-width: var(--content-max-width);
  margin: auto;

  .Underdel {
    position: relative;
    &-required-flag {
      color: grey;
      font-size: 14px;
      margin-left: 20px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

.submit-btn {
  cursor: pointer;
  background-color: $color--glassbla03;
  border: 0;
  border-radius: 2px;
  font-family: $font-gt-america;
  font-size: 1rem;
  line-height: 1.1875rem;
  display: flex;
  align-items: center;
  height: 46px;
  padding: 0 15px;
  color: $color--white;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  margin-left: auto;

  &:hover {
    background-color: $color--glassbla02;
  }

  &:disabled {
    opacity: 0.5;
  }

  img {
    margin-right: 10px;
  }
}
