.ArealPerPlan {
  display: grid;
  grid-template-columns: repeat(1, 1fr 1fr auto);
  align-content: start;
  grid-row-gap: 5px;
  grid-column-gap: 5px;

  &__Label {
    grid-column: span 3;
    margin-bottom: -5px;
  }

  &__entry {
    &__type {
      margin-left: 8px;
    }

    &__avstand {
      margin-left: 12px;
    }

    &__delete {
      margin: 0 auto;
      font-size: 15px;
    }

    &__errorMessage {
      grid-column: span 2;
    }
  }
}
