.AddLoggedEvent {
  @include event-log-grid-style;

  > p {
    font-family: var(--fonts-secondary);
    font-weight: 500;
    color: #147E88;
    text-align: left;
    font-size: 17px;
  }

  width: 100%;
  display: grid;
  padding: 15px 20px;
  border: 1px #e3e3e3 solid;
  background-color: #fdfdfd;
  margin: 0 0 15px 0;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e4e4e4;
  }

  .add-icon {
    grid-column: 5/6;
    background: none;
    color: #147E88;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
  }
}

.EventLogHeader {
  @include event-log-grid-style;

  >p {
    font-family: var(--gt-america-mono);
    color: #147E88;
  }
}

.EventLog {
  display: flex;
  flex-direction: column;
  padding: unset;
  margin: unset;

  .LoggedEventRow {
    border-bottom: 1px solid #E6E6E6;
  }

  .item-content,
  .item-header {
    &.-isOpen {
      background-color: #bfe0e44d;

      .LoggedEventRow {
        background-color: #bfe0e44d;
      }
    }
  }
}
