.NumberField {
  display: grid;
  row-gap: 15px;

  & .NumberInput {
    display: grid;
    grid-template-areas: "main";
    grid-template-columns: 1fr;

    & input {
      grid-area: main;
      font-family: var(--gt-america-mono);
    }

    & input + span {
      grid-area: main;
      align-self: center;
      pointer-events: none;
      font-family: var(--gt-america-mono);
      padding-left: 14px;
      color: #666666;

      &:not([data-value]) {
        visibility: hidden;
      }

      &::before {
        content: attr(data-value) " ";
        color: transparent;
      }
    }
  }
}
