.document-delivery-page {
  position: relative;
  
  &-deadline {
    margin-bottom: 2em;
    display: block;
  }

  &-title-wrapper{
    display: flex;
    align-items: flex-start;
    gap: 3em;

    .recind-label{
      padding: 10px 15px 10px 15px;
      background-color: #ffa7a7;
      border-radius: 30px;
      font-weight: 700;
      font-size: 16px;
      margin-top: 17px;
    }
  }

  &-list {
    .dokument {
      .UploadedFileLabel,
      .advanced-file-selector {
        margin-bottom: 2rem;
      }

      &-title {
        display: flex;

        span:last-of-type {
          margin-left: 1em;
          font-size: 15px;
          color: gray;
        }
      }
    }
  }

  &-preamble {
    max-width: 45em;
    margin-bottom: 2em;
  }

  &-confirmation {
    .check-guard {
      margin-bottom: 2em;
    }
  }
  
}
