@import '../vars.scss';

.rapport {
  h3 {
    font-size: 1.2rem;
    line-height: 3.4375rem;
    font-family: $font-gt-america-bold;
    margin: 0;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .summary {
    &__item {
      display: grid;
      grid-template-columns: 1fr 2fr;
      column-gap: 40px;
      margin-bottom: 70px;
    }
  }

  .form {
    margin-top: 40px;

    &__row {
      display: grid;
      grid-template-columns: 1fr 2fr;
      column-gap: 40px;
      margin-top: 50px;
      margin-bottom: 50px;


      h3{
        line-height: unset;
      }

      p,
      .checkbox {
        margin-top: 10px;
      }

      button {
        margin-top: 30px;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    &__comment {
      position: relative;

      button {
        position: absolute;
        top: 0;
        right: -32px;
        padding: 0;
        background: none;
        border: 0;
        margin-top: 0;
        cursor: pointer;
      }
    }
  }

  .attachments {
    position: relative;
    margin-bottom: 50px;

    h3 {
      font-size: 25px;
      margin: 2rem 0;
    }

    .advanced-file-selector {
      margin-bottom: 50px;
    }
  }
  
}
