.UploadButton {
  display: inline-block;
  background-color: var(--button);
  color: white;
  cursor: pointer;
  padding: 7px;
  height: var(--button-height);
  width: var(--button-height);
  position: relative;
  transition: all 0.3s ease 0s;
  border: 2px solid var(--button);

  svg {
    font-size: 28px;
  }

  input[type=file] {
    display: none;
  }
}

.UploadButton:hover {
  background-color: var(--button-hover);
}

.UploadButton-failed {
  position: absolute;
  bottom: 100%;
  box-shadow: 0 0 8px #00000088;
  border: 1px solid currentColor;
  color: #dd0000;
  left: -250%;
  width: 600%;
  border-radius: 5px;
  z-index: 1;
  margin-bottom: 12px;
}

.UploadButton-failed::after {
  content: "";
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  background: #ffffff;
  position: absolute;
  box-shadow: inherit;
  z-index: -1;
  bottom: -10px;
  left: calc(50% - 10px);
  border-left: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
}

.UploadButton-failed > span {
  padding: 5px;
  background: #ffffff;
  display: block;
  border-radius: 5px;
}

.buttonAsLink {
  padding: 15px;
  font-family: var(--gt-america);
  color: #006699;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  min-width: 50%;
  background: white;
  border: 2px solid black;
  border-style: dashed;

  input[type="file"] {
    display: none;
  }

  svg {
    width: 32px;
    height: 32px;
    margin-bottom: 20px;

    path {
      stroke: #006699;
    }
  }
}
