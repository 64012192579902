.error-msg{
    animation-name: animScale;
  animation-duration: 0.35s;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0%;
  max-width: 17em;
  background-color: #ffecec;
  padding: 10px 20px;
  font-weight: 500;
  border: 3px solid #ff070766;
  border-radius: 5px;
  text-align: center;
  box-shadow: 1px 1px 1px;
  }

  
  @keyframes animScale {
    0% {
      opacity: 0;
      transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
  }