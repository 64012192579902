@import '../../scss/vars.scss';

.file-list {
  padding: 0;
  list-style: none;

  li {
    display: flex;
    margin-top: 15px;

    a {
      height: 46px;
      padding: 0 15px;
      background-color: $color--input-border-gray;
      width: 100%;
      margin-right: 15px;
      display: flex;
      align-items: center;
      color: $color--betong;
      text-decoration: none;

      &:visited {
        color: $color--betong;
      }
    }

    button {
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      background-color: $color--glassbla03;
      padding: 0;
      border: 0;
      transition: 0.3s ease;

      &:hover {
        background-color: $color--glassbla02;
      }

      span {
        svg {
          width: 12px;
          height: 12px;
          fill: $color--white;
        }
      }
    }
  }
}
