.LoggedEventRow {
  &.-phase {
    background-color: #EBEBEB;
    padding: 30px 20px;
    display: grid;
    grid-template-columns: 1fr 4em;
    justify-items: center;

    .phase-row--icon {
      justify-content: flex-start;
      justify-self: flex-start;
      grid-column: 2;
      margin-left: 3px;
      align-items: center;
      min-width: 4.5em;

      svg {
        color: $color--glassbla03;
      }
    }
  }
}
