.KartRedigerSide {
  &__help-image {
    position: absolute;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 70vh;
    background-image: url(./arcgisHelpImage.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__toolbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__image-container {
    height: 50em;
    width: 100%;
  }
}
