.PubliserKonkurransegrunnlagSide {
  .Deler {
    section {
      margin: 5vh 0;

      h2 {
        margin: 0;
      }
    }

    .Underdel {
      padding: 1vh 0 0 1vw;
    }
  }
}
