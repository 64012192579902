.RollerOgBrukereSide {
  table {
    width: 100%;
    table-layout: fixed;

    th {
      text-align: left;
      color: $color--glassbla02;

      a {
        text-decoration: none;
        color: #147e87;

        svg {
          position: absolute;
          font-size: 22px;
        }
      }

      :hover {
        text-decoration: underline;
      }
    }

    td {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td,
    th {
      padding: 15px 0;
    }

    td.indikator,
    th.indikator {
      width: 25px;
    }

    td a {
      text-decoration: none;
      color: inherit;
    }

    .statsbygg-ikon {
      height: 18px;
    }
  }
  &__pages {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin-top: 1em;

    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      justify-self: center;

      &:first-child {
        justify-content: flex-start;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }

    &__page-selection {
      width: 97%;
      overflow-x: hidden;
    }
  }
}
