:root {
  --content-max-width: 1260px;
  --breakpoint-hd: 1260px;
  --breakpoint-desktop: 1024px;
  --breakpoint-tablet: 768px;
  --button-height: 46px;
}

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  font-family: var(--fonts);
}

#pageMountPoint {
  --page-margin: 2em 0;
  --page-padding: 0;

  flex: 1;
  max-width: var(--content-max-width);
  width: 100%;
  margin: 0 auto;
  padding: 80px 30px;
}

@media (max-width: 768px) {
  #pageMountPoint {
    padding: 15px;
  }
}

h5 {
  margin: 0;
  font-family: var(--fonts);
  font-size: 16px;
  font-weight: 600;
}


.disabled {
  border: 2px dashed rgba(128, 0, 0, 0.5);
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  opacity: 0.5;
}

.spin {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
