.ProsjekterSide {
  & > h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .ProsjekterSide__Buttons {
      display: flex;
      font-size: 50px;

      a {
        display: inline-flex;
        color: #00626E;
      }
    }
  }
}
