.LoggedEventRow {
  @include event-log-grid-style;

  font-size: 18px;
  font-family: var(--gt-america);
  font-weight: 400;
  transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: white;
  cursor: pointer;

  .row--role {
    display: flex;
    align-items: center;

    &--icon {
      width: 32px;
      height: 32px;
      border: 3px solid #ffffff;
      border-radius: 50%;
      margin-right: 10px;
      display: inline-block;
    }
  }

  .row--icon {
    grid-column: 5/6;
    justify-self: flex-start;
    display: flex;
    align-items: center;
    min-width: 4.5em;
    justify-content: flex-end;

    svg {
      color: $color--glassbla03;
    }
  }
}

.expanded-content {
  font-size: 16px;
  font-family: var(--gt-america);
  font-weight: 400;
  display: grid;
  grid-template-columns: 2fr 5fr 2fr 2fr 1fr;
  padding: 15px 20px;

  &-col3 {
    grid-column: 4/6;
  }

  .attachments {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 1rem;

      span {
        display: block;
      }

      a,
      a:visited {
        text-decoration: none;
        color: $color--glassbla03;
        display: flex;

        svg {
          color: $color--betong;
          margin-right: 16px;
        }

        span {
          text-decoration: underline;
        }

        &:hover {
          span {
            text-decoration: none;
          }
        }
      }
    }
  }

  .wrapped-field {
    display: flex;
    flex-direction: column;

    &--header {
      font-weight: bold;
      margin-bottom: 12px;
    }

    &--content {
      white-space: pre-wrap;
    }
  }

  .noWrap-field {
    display: flex;

    &--header {
      font-weight: bold;
      margin-right: 8px;
    }
  }
}

.-isStriked {
  >* {
    text-decoration: line-through;
  }
}
