@media print {
  * {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  body {
    font-size: 10px;
    padding: 0 20px;
  }

  #headerMountPoint,
  #footerMountPoint {
    display: none;
  }
}
