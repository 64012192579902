.modal{
    .cancel-comp-button{
        cursor: pointer;
        border: 0;
        border-radius: 2px;
        font-family: var(--gt-america);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.1875rem;
        display: flex;
        align-items: center;
        height: 46px;
        padding: 0 15px;
        color: white;
        transition: all 0.3s ease 0s;
        background-color: $color--glassbla03;
        &:hover {
          background-color: #22828f;
        }
        margin-top: 2em;
    }
}