.ProfilSide {
  display: grid;
  grid-template-areas:
    "heading heading"
    "info ProfilBildePanel"
    "info submitArea"
    "info submitArea";
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template-columns: 5fr 5fr;
  position: relative;
  padding: 4em 0 3em 0;

  &__backdrop {
    background-color: #DDE2ED;
    width: calc(100vw - 17px);
    left: 50%;
    height: 100%;
    transform: translate(-50%, 0);
    position: absolute;
    z-index: -1;
  }

  @include breakpoint-below-tablet {
    grid-column-gap: 30px;
  }

  // landscape phones
  @media (max-width: 576px) {
    grid-template-areas:
      "heading"
      "info"
      "ProfilBildePanel"
      "submitArea";
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }

  & h1 {
    grid-area: heading;

    @include breakpoint-below-tablet {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  &__info {
    grid-area: info;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 24px;
    align-items: baseline;
    padding-left: 18px;

    @include breakpoint-below-tablet {
      grid-template-columns: 1fr;
      row-gap: 20px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    &-hoyre-redigerbar {
      padding-left: 0;
      width: 340px;

      @include breakpoint-hd {
        width: 360px;
      }
    }
  }

  &__submitArea {
    display: grid;
    grid-area: submitArea;
    grid-template-areas:
      "title title"
      "action button";
    grid-template-columns: 3fr 1fr;

    @include breakpoint-below-tablet {
      grid-template-areas:
        "title"
        "action"
        "button";
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }

    h5 {
      grid-area: title;
    }

    .varselWrapper {
      grid-area: action;
      display: flex;
      align-items: flex-start;

      p {
        margin: 0 0 0 10px;
      }
    }

    button {
      grid-area: button;
      margin: auto;
    }
  }
}

.loginDataFooter {
  display: grid;
  grid-template-columns: 5fr 6fr;
  grid-column-gap: 30px;
  margin-top: 3em;

  @include breakpoint-below-tablet {
    grid-template-columns: 1fr;

    >div {
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-bottom: 2em;
    }
  }

  > div {
    display: flex;

    >* {
      font-family: var(--gt-america);
    }

    >h5 {
      text-transform: uppercase;
      font-weight: 400;
      margin-right: 1em;
    }

    >div {
      font-weight: 700;
    }
  }
}
