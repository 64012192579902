.EierDataBlockDisplayOnly {
  display: flex;
  flex-direction: row;
  background-color: #F6F7F8;
  padding: 2rem 2rem;
  margin: 2rem 0;
  border: 1px solid #dbdbdb;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &.IsOld {
    .Left .Org-data p {
      text-decoration: line-through;
    }

    .Right {
      margin-left: 5vw;
      @media (max-width: 768px) {
        margin-left: 0;
      }
    }
  }

  .Org-links {
    display: flex;

    a {
      margin-right: 2rem;
      margin-top: 1rem;
      white-space: break-spaces;
    }
  }

  .EierLabel {
    margin-bottom: 1em;
  }

  .Org-data {
    [variant="paragraph-header"] {
      margin-bottom: 0;
    }
  }
}
