.SelectField {
  display: grid;
  row-gap: 5px;
  column-gap: 15px;

  &.Loading {
    cursor: wait;
    filter: opacity(0.3);
  }

  & > div > div:first-of-type {
    // This is needed for it to look like other input values
    border-radius: 0;
    height: var(--button-height);
    border: 1px solid var(--color--input-border-gray);
  }

  & &__ErrorBorder > div:first-of-type {
    border: 2px solid #d0021b;
  }
}
