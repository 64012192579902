.heading{
  display: flex;
  align-items: flex-start;
  gap: 2em;
  justify-content: space-between;

  .recindLabel{
    padding: 10px 15px 10px 15px;
    background-color: #ffa7a7;
    border-radius: 30px;
    font-weight: 700;
    font-size: 16px;
  }
}

.OkonomiEvaluering {
  display: flex;
  flex-direction: column;
  background-color: #88C9D04D;
  padding: 2rem 2rem;

  .Okonomi-row {
    font-family: var(--gt-america);
    margin-bottom: 15px;
    font-weight: 500;

    > div {
      background-color: white;
    }
  }

  .CommentArea {
    margin-top: calc(2em - 15px);
    margin-bottom: 2em;
  }

  .max-char-number {
    margin: unset;
    margin-left: auto;
  }
}

.OkonomiEvaluering__footer {
  margin-top: 2vh;
  display: flex;
  justify-content: end;
}
