.dots {
  border: 0;
  margin: 0 1rem;
  background: none;
  padding: 6px 2px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
    box-shadow: 1px 1px 3px 2px #a5a5a5;
  }

  &-content {
    display: none;
    background-color: white;
    position: absolute;
    top: 36px;
    z-index: 100;
    border-radius: 5px;
    flex-direction: column;
    box-shadow: 2px 2px 6px 2px #bbbbbb;

    div {
      background-color: white;
      border: none;
      width: 100%;
      padding: 10px 30px;
      border-radius: 5px;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background-color: #f1efef;
        border-radius: 0;
      }
    }
  }

  &:focus .dots-content {
    display: flex;
  }
}
