.KollektivTransport {
  display: grid;
  grid-template-columns: repeat(1, 1fr 1fr auto);
  align-content: start;
  align-items: center;
  grid-row-gap: 5px;
  grid-column-gap: 15px;

  &__Label {
    grid-column: span 3;
    margin-bottom: -5px;
  }

  &__new-field {
    display: grid;
  }

  & .SelectField {
    grid-column: span 2;
  }

  & &__button {
    margin-bottom: 0;
  }

  &__entry {
    display: grid;
    grid-row: 1fr 1fr 20px 20px;

    &__type {
    }

    &__avstand {
      margin-left: 12px;
    }

    &__delete {
      margin: 0 auto;
      font-size: 15px;
      display: flex;
    }

    &__errorMessage {
      grid-column: span 2;
    }
  }
}
