.document-delivery-page {
  position: relative;

  &-list {
    .dokument {
      .UploadedFileLabel,
      .advanced-file-selector {
        margin-bottom: 2rem;
      }

      &-title {
        display: flex;

        span:last-of-type {
          margin-left: 1em;
          font-size: 15px;
          color: gray;
        }
      }
    }
  }

  .Evaluation {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #88C9D04D;
    padding: 2rem 2rem;

    .Okonomi-row {
      font-family: var(--gt-america);
      margin-bottom: 15px;
      font-weight: 500;

      > div {
        background-color: white;
      }
    }

    &-comment {
      margin-top: calc(2em - 15px);

      .max-char-number {
        text-align: right;
        margin-left: auto;
      }
    }
  }
}
