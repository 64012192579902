.PubliseringRedigerSide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  align-items: start;

  & h2 {
    grid-column: span 2;
  }
}
