.pageLinkMenu {
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;

  .link {
    padding: 12px 30px;
    font-family: var(--gt-america);
    text-transform: capitalize;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 22px;
    color: var(--secondary_05);
    cursor: pointer;

    &:not(.-unavailable):hover {
      border-bottom: 5px solid var(--primary_01);
    }

    &.-active {
      border-bottom: 5px solid var(--primary-color) !important;
      font-weight: 700;
    }
    &.-unavailable {
      color: #aaaaaa;
    }
    &.dropdown {
      background: none;
      border: 0;
      font-weight: 400;
    }
  }

  &.-bottom-margin {
    margin-bottom: 4em;
  }

  &.-top-bot-margin{
    margin: 2em 0em;
  }

  @media (max-width: 768px) {
    &.-is-global {
      display: none;
    }
  }
}

.on-grid {
  grid-area: cfMenu / cfMenu;
}

@media (max-width: 768px) {
  .on-grid {
    grid-area: cfMenu;
  }
}
