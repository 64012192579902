.TilbudteObjekter{
    ul{
        padding: unset;
        list-style: none;
        li{
            margin-bottom: 2em;
        }
    }

    .-with-deadline{
        display: flex;
        justify-content: space-between;
        @include breakpoint-below-tablet{
            flex-direction: column;
        } 
    }
}