.heading {
  margin: 0;
  font-family: $font-family;
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 30px;
}

.-large {
  font-size: 60px;
}
