@import '../../scss/vars.scss';

.file-selector {
  label {
    display: flex;

    input {
      display: none;
    }
  }

  &__label {
    padding: 0 15px;
    border: 2px solid rgba(230, 230, 230, 1);
    height: 46px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    color: #767676;
    font-size: 0.9375;
    line-height: 1.25rem;
    font-family: $font-gt-america;
  }

  &__icon {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: $color--glassbla03;
    transition: 0.3s ease;

    &:hover {
      background-color: $color--glassbla02;
    }

    span {
      svg {
        width: 12px;
        height: 12px;
        fill: $color--white;
      }
    }
  }
}
