.SvarListeSide {
  h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  a {
    display: inline-flex;
    color: #00626e;
  }
  .empty-cell {
    height: 35px;
    width: 35px;
  }
}
