.footer {
  background-color: $color--glassbla04;
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 0 auto;

  &__container {
    width: $content-max-width;
    padding: 60px 30px;

    @include breakpoint-below-tablet {
      padding: 15px;
    }
  }

  * {
    color: white !important;
  }

  &__info-border {
    width: 100%;
    border-bottom: 1px solid white;
    padding-bottom: 12px;
    color: white;
  }

  &__content {
    width: 100%;
    max-width: $content-max-width;
    display: flex;
    justify-content: space-between;
    color: $color--white;
    line-height: 2;
    font-family: $font-family-secondary;
    font-size: 18px;

    @include breakpoint-below-tablet {
      flex-direction: column;
      align-items: flex-start;
    }

    a {
      color: inherit;
      font-weight: bold;
      display: block;
      margin-bottom: 16px;
    }
  }

  &__credits {
    text-align: right;

    @include breakpoint-below-tablet {
      text-align: left;
      margin-top: 30px;
    }
  }
}

.environment {
  position: fixed;
  top: 0;
  left: 0;
  width: 30px;
  background: red;
  bottom: 0;
  opacity: 0.8;
}

.environment.Developmentleie,
.environment.Devleie {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='300px' width='30px'><text transform='translate(10, 0) rotate(90)' fill='white' font-family='sans-serif' font-size='20'>Dev leiesøk</text></svg>");
}

.environment.Developmenttomt,
.environment.Devtomt {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='300px' width='30px'><text transform='translate(10, 0) rotate(90)' fill='white' font-family='sans-serif' font-size='20'>Dev tomtesøk</text></svg>");
}

.environment.Testleie {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='300px' width='30px'><text transform='translate(10, 0) rotate(90)' fill='white' font-family='sans-serif' font-size='20'>Test leiesøk</text></svg>");
}

.environment.Testtomt {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='300px' width='30px'><text transform='translate(10, 0) rotate(90)' fill='white' font-family='sans-serif' font-size='20'>Test tomtesøk</text></svg>");
}

.environment.Qaleie {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='300px' width='30px'><text transform='translate(10, 0) rotate(90)' fill='white' font-family='sans-serif' font-size='20'>QA leiesøk</text></svg>");
}

.environment.Qatomt {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='300px' width='30px'><text transform='translate(10, 0) rotate(90)' fill='white' font-family='sans-serif' font-size='20'>QA tomtesøk</text></svg>");
}
