@import "../../../scss/fonts.scss";

.LogEntryForm {
  display: grid;
  max-width: calc(790px + 2rem);
  width: 80vw;
  min-width: 20rem;
  padding: 2em 2em;
  overflow: auto;
  max-height: calc(100vh - 14rem);

  &--generic {
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-gap: 2rem;
    margin-bottom: 2em;
  }

  &--email {
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-gap: 2rem;
    margin-bottom: 2em;
  }

  .picker {
    border: 0;
  }

  .span-half {
    grid-column: span 1;
    width: 100%;
    min-width: 10rem;
  }

  .span-whole {
    grid-column: 1/3;
    width: 100%;
  }

  .context-area {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #ECF6F7;
    grid-column: 1/3;
    grid-gap: 2rem;
    padding: 1rem 2rem;

    &--title {
      line-height: 1.5rem;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    .attachments {
      .UploadField {
        &__label {
          background-color: white;
        }
      }
    }
  }

  &--button {
    margin-bottom: 2em;
  }
}
