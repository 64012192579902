.TimePicker {
  display: flex;
  height: var(--button-height);
  border: 1px solid var(--color--input-border-gray);
  border-radius: 0;
  font-family: var(--gt-america-mono);
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  align-items: center;

  &.-error {
    border: 2px solid red;
  }

  &--hh {
    border: 0;
    width: 2em;
    text-align: center;
    height: 100%;
    outline: none;
  }

  &--mm {
    border: 0;
    width: 100%;
    text-align: start;
    height: 100%;
    outline: none;
  }

  svg {
    margin-left: auto;
    margin-right: 13px;
  }
}
