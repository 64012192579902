.SeriositetEvaluering {
  display: flex;
  flex-direction: column;
  background-color: #88C9D04D;
  padding: 2rem 2rem;

  .CommentArea {
    margin-top: calc(2em - 15px);
  }

  .max-char-number {
    margin: unset;
    margin-left: auto;
  }

  .approved-text {
    margin-bottom: 1em;
  }

  .font-paragraph {
    margin-top: 1em;
    font-family: var(--gt-america);
    font-weight: 500;
  }

  &--bottom {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;

    p {
      max-width: 60%;
    }
  }
}
