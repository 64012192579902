.FagRessursSelector {
  display: grid;
  align-content: start;
  align-items: center;
  grid-row-gap: 5px;
  grid-column-gap: 15px;
  grid-template-columns: 4fr auto;

  .SelectField {
    grid-column: 1 / 3;
  }

  &--add-lines {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    grid-row-gap: 5px;
    height: 100%;
    grid-column-gap: 15px;
  }
}
