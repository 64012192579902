.SearchField {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 0fr;
  position: relative;

  button {
    align-self: flex-end;
    margin-right: auto;
  }

  .close {
    position: absolute;
    right: 90px;
    top: 56%;
    font-size: 23px;

    &:hover {
      cursor: pointer;
    }
  }
}
