.DeleteButton {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: var(--gt-america);
  color: #006699;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}
