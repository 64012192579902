.complete {
  margin-bottom: 50px;
  padding: 30px 40px;
  background-color: rgba(136, 201, 208, 0.3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &__content {
    width: 100%;

    .row {
      margin-bottom: 1em;
      .checkbox-disclaimer {
        margin-bottom: 1em;
      }
    }
  }

  &__bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    .text {
      max-width: 62%;
      font-family: $font-family-secondary;
      font-size: 1rem;
    }

    button {
      cursor: pointer;
      background-color: $color--glassbla03;
      border: 0;
      border-radius: 2px;
      font-family: $font-gt-america;
      font-size: 1rem;
      line-height: 1.1875rem;
      display: flex;
      align-items: center;
      height: 46px;
      padding: 0 15px;
      color: $color--white;
      transition: all 0.3s ease 0s;
      white-space: nowrap;

      &:hover {
        background-color: $color--glassbla02;
      }

      &:disabled {
        opacity: 0.5;
      }

      img {
        margin-right: 10px;
      }
    }
  }
}
