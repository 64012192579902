.InnkommetKonkurransegrunnlagSide,
.InnkommetKonkurransegrunnlagForhandlingerSide {
  .title-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 3em;

    .recind-label {
      padding: 10px 15px 10px 15px;
      background-color: #ffa7a7;
      border-radius: 30px;
      font-weight: 700;
      font-size: 16px;
      margin-top: 17px;
    }
  }
  .complete {
    gap: 20px;

    .vurdering {
      margin-bottom: 30px;
    }
  }
  .Deler {
    section {
      margin: 5vh 0;

      h2 {
        margin: 0;
      }
    }

    .Underdel {
      padding: 1vh 0 0 1vw;
    }
  }
}
