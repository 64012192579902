.esri-component.esri-attribution.esri-widget,
.esri-sketch__button.esri-icon-settings {
  display: none !important;
}

.esri-ui-corner {
  margin-right: 20px;
}

.esri-sketch__section.esri-sketch__tool-section:last-child,
.esri-custom-section:last-child {
  border-right: none !important;
}

.esri-custom-toolbar {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  color: #323232;
  font-size: 14px;
  font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.3em;
  background-color: #ffffff;
  pointer-events: auto;
  margin-bottom: 10px;
}

.esri-custom-toolbar .esri-custom-panel {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
}

.esri-custom-toolbar .esri-custom-panel .esri-custom-section {
  border-right: 1px solid rgba(110, 110, 110, 0.3);
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  padding: 0 7px;
  margin: 6px 0;
}

.esri-custom-button {
  position: relative;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #6e6e6e;
  display: flex;
  font-size: 16px;
  height: 32px;
  justify-content: center;
  text-align: center;
  transition: background-color 125ms ease-in-out;
  width: 32px;
}

.esri-custom-button.toggled {
  background: #4c4c4c;
  color: #ffffff;
}

.esri-component .esri-layer-list .esri-widget .esri-widget--panel {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  color: #323232;
  font-size: 14px;
  font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.3em;
  background-color: #ffffff;
  pointer-events: auto;
  margin-bottom: 10px;
}
