.recind-offer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &--text {
    max-width: 50em;
  }

  &--button {
    cursor: pointer;
    border: 0;
    border-radius: 2px;
    font-family: var(--gt-america);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    display: flex;
    align-items: center;
    height: 46px;
    padding: 0 15px;
    color: white;
    transition: all 0.3s ease 0s;
    background-color: $color--glassbla03;

    &.-warning {
      background-color: #ffa69a;
      text-transform: capitalize;
      color: black;
      font-weight: 500;
      span {
        margin-right: 0.5em;
      }
    }
    &.-primary {
      background-color: $color--glassbla03;
      &:hover {
        background-color: #22828f;
      }
    }
    &.-secondary {
      background-color: transparent;
      color: black;
      border: 2px solid $color--glassbla03;
    }
  }

  &--buttons {
    display: flex;
    margin-top: 2em;
    gap: 1.4em;
  }
  &.-disabled {
    opacity: 0.5;
  }
}
