/* STATENS LOKALER COLOR PALETTE */
:root {
  --color: #131615;
  --primary-color: rgb(20, 126, 135); /* #147e87 */
  --background-color: rgba(247, 248, 250, 1); /* #f7f8fa */
  --primary-font-color: rgb(35, 47, 58); /* #232f3a */
  --secondary-font-color: #c6c6c6;
  --defaultColor: #232f3a;

  /* colors for input fields */
  --field-color: #e6e6e6;
  --focus-color: #147e87;
  --error-color: #d0021b;

  /* Button colors */
  --button: #005763;
  --button-color: #ffffff;
  --button-hover: #147e88;
  --button-focus: rgba(35, 163, 255, 1);
  --button-disabled: rgba(196, 196, 196, 1);
  --button-disabled-darker: rgba(130, 130, 130, 1);
  --secondary-button-color: #147e87;
  --secondary-button: rgba(0, 0, 0, 0);
  --secondary-button-hover-color: #ffffff;
  --secondary-button-hover: var(--button);

  /* Anchor colors */
  --linkColor: rgba(0, 91, 156, 1);
  --linkColorBoxShadow: #1db4c2;
  --linkVisited: #945e88;
  --linkVisitedBoxShadow: #bf7ab0;
  --linkHover: #005763;
  --linkHoverBoxShadow: #007c8c;
  --linkActive: #945e88;
  --linkFocus: #ffffff;
  --linkBreadcrumb: #232f3a;
  --linkBreadcrumb: #232f3a;
  --someBackground: rgba(226, 249, 252, 1);
  --wizard-line: #88D0C5;

  /* STATSBYGG PRIMARY COLORS */

  /* Packet colors */
  --packet-color-nav: #e1e6f4;
  --packet-color-nav-hover: #bcc7e6;
  --packet-color-bg: rgba(136, 201, 208, 1); /* #88c9d0 */
  --packet-color-font: #666666;

  /* Statsbygg Glassblå 01-03 */
  --primary_01: rgb(136, 201, 208); /* #88c9d0 */
  --primary_02: rgb(20, 126, 136); /* #147e88 */
  --primary_03: rgb(0, 87, 99); /* #005763 */
  --primary_04: #E2F9FC;

  /* SECONDARY COLORS - NEUTRAL */

  /* Statsbygg Nøytral grå 01-02 */
  --secondary_01: rgb(198, 198, 198); /* #c6c6c6 */
  --secondary_02: rgb(227, 227, 227); /* #e3e3e3 */

  /* Statsbygg Stein */
  --secondary_03: rgb(35, 47, 58); /* #232f3a */

  /* Statsbygg Jord */
  --secondary_04: rgb(44, 8, 8); /* #2c0808 */

  /* Statsbygg betong */
  --secondary_05: #1d1d1d; /* #232f3a */

  /* TERTIARY COLORS - MARKER COLORS */

  /* Statsbygg Sennep */
  --tertiary_01: rgb(255, 221, 18); /* #ffdd12 */

  /* Statsbygg Multe */
  --tertiary_02: rgb(244, 159, 84); /* #f49f54 */

  /* Statsbygg Laks */
  --tertiary_03: rgb(237, 128, 137); /* #ed8089 */

  /* Statsbygg Lyng */
  --tertiary_04: rgb(148, 94, 136); /* #945e88 */

  /* Updated colors */
  --new-primary_01: #dde2ed;

  /* FROM SCSS (REFACTOR THESE AWAY) */
  --color--glassbla01: #88c9d0;
  --color--glassbla02: #147e87;
  --color--glassbla03: #005763;
  --color--glassbla04: rgb(0, 87, 99); /* #005763 */
  --color--jord: #9a7512;
  --color--betong: #1d1d1b;
  --color--white: #ffffff;
  --color--background: #f7f8fa;
  --color--signalgul: #ffe600;
  --color--vern: #ff9900;
  --color--rust: #cf4f13;
  --color--interior: #9c296d;
  --color--miljo: #5c9424;
  --color--stein: #232f3a;
  --color--input-border-gray: #e6e6e6;
}
