.FullforBefaringSkjema {
  display: grid;
  grid-template-columns: 1;
  grid-row-gap: 2em;

  &.-levert {
    display: grid;
    grid-template-columns: 7em auto;
    grid-template-areas:
      "message message"
      "undo confirm";

    p {
      grid-area: message;
    }

    .undo {
      grid-area: undo;
    }

    .confirm {
      grid-area: confirm;
    }
  }
}
