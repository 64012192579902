/* FONTS */

/* oswald-regular - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../assets/fonts/oswald-v31-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/oswald-v31-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* oswald-600 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('../assets/fonts/oswald-v31-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/oswald-v31-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../assets/fonts/open-sans-v17-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans-v17-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans-v17-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/open-sans-v17-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans-v17-latin-regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('../assets/fonts/open-sans-v17-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans-v17-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans-v17-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/open-sans-v17-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans-v17-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../assets/fonts/open-sans-v17-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans-v17-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans-v17-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/open-sans-v17-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Canela Web';
  src: url('../assets/fonts/Canela/Canela-Regular-Web.woff2') format('woff2'),
    url('../assets/fonts/Canela/Canela-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Canela Text Web';
  src: url('../assets/fonts/Canela/CanelaText-Regular-Web.woff2')
      format('woff2'),
    url('../assets/fonts/Canela/CanelaText-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('../assets/fonts/GT-America/GT-America/GT-America-Standard-Regular.woff2')
      format('woff2'),
    url('../assets/fonts/GT-America/GT-America/GT-America-Standard-Regular.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'GT America Bold';
  src: url('../assets/fonts/GT-America/GT-America-Bold/GT-America-Standard-Bold.woff2')
      format('woff2'),
    url('../assets/fonts/GT-America/GT-America-Bold/GT-America-Standard-Bold.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'GT America Compressed Bold';
  src: url('../assets/fonts/GT-America/GT-America-Compressed-Bold/GT-America-Compressed-Bold.woff2')
      format('woff2'),
    url('../assets/fonts/GT-America/GT-America-Compressed-Bold/GT-America-Compressed-Bold.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'GT America Condensed Bold';
  src: url('../assets/fonts/GT-America/GT-America-Condensed-Bold/GT-America-Condensed-Bold.woff2')
      format('woff2'),
    url('../assets/fonts/GT-America/GT-America-Condensed-Bold/GT-America-Condensed-Bold.woff')
      format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'GT America Condensed Medium';
  src: url('../assets/fonts/GT-America/GT-America-Condensed-Medium/GT-America-Condensed-Medium.woff2')
      format('woff2'),
    url('../assets/fonts/GT-America/GT-America-Condensed-Medium/GT-America-Condensed-Medium.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'GT America Mono';
  src: url('../assets/fonts/GT-America/GT-America-Mono/GT-America-Mono-Regular.woff2')
      format('woff2'),
    url('../assets/fonts/GT-America/GT-America-Mono/GT-America-Mono-Regular.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'GT America Mono Bold';
  src: url('../assets/fonts/GT-America/GT-America-Mono-Bold/GT-America-Mono-Bold.woff2')
      format('woff2'),
    url('../assets/fonts/GT-America/GT-America-Mono-Bold/GT-America-Mono-Bold.woff')
      format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

:root {
  --fonts: 'Open Sans', impact, charcoal, sans-serif;
  --fonts-secondary: helvetica, 'Oswald', arial, helvetica, sans-serif;
  --canela: 'Canela Web';
  --canela-text: 'Canela Web Text';
  --gt-america: 'GT America';
  --gt-america-bold: 'GT America Bold';
  --gt-america-compressed-bold: 'GT America Compressed Bold';
  --gt-america-condensed-bold: 'GT America Condensed Bold';
  --gt-america-condensed-medium: 'GT America Condensed Medium';
  --gt-america-mono: 'GT America Mono';
  --gt-america-mono-bold: 'GT America Mono Bold';
}

.font-menu-main {
  font-family: var(--gt-america-condensed-medium);
  font-size: 22px;
  margin-bottom: 0;
  line-height: 28px;
  color: #000000;
}

.font-menu-sub {
  font-family: var(--gt-america-mono);
  font-size: 15px;
  margin-bottom: -10px;
  line-height: 19px;
  color: #000000;
}
.font-tag {
  font-family: var(--gt-america-mono);
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 22px;
  color: #000000;
}

.font-tag-bold {
  font-family: var(--gt-america-mono-bold);
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 22px;
  color: #000000;
}

.font-article-h1 {
  font-family: var(--canela);
  font-size: 60px;
  margin-bottom: 5px;
  line-height: 78px;
  color: #000000;
}
.font-article-h2 {
  font-family: var(--canela);
  font-size: 50px;
  margin-bottom: 5px;
  line-height: 62px;
  color: #000000;
}
.font-article-h3 {
  font-family: var(--canela);
  font-size: 40px;
  margin-bottom: 5px;
  line-height: 55px;
  color: #000000;
}
.font-article-ingress-1-2 {
  font-family: var(--canela-text);
  font-size: 20px;
  margin-bottom: 5px;
  line-height: 30px;
  color: #000000;
}
.font-article-ingress-3-4 {
  font-family: var(--canela-text);
  font-size: 20px;
  margin-bottom: 5px;
  line-height: 28px;
  color: #000000;
}

.font-project-h1 {
  font-family: var(--gt-america-compressed-bold);
  text-transform: capitalize;
  font-size: 40px;
  margin-bottom: 5px;
  line-height: 43px;
  color: #000000;
}

.font-byline {
  font-family: var(--gt-america-mono);
  font-size: 13px;
  margin-bottom: 24px;
  line-height: 20px;
  color: #000000;
}

.font-byline-bold {
  font-family: var(--gt-america-mono-bold);
  font-size: 13px;
  margin-bottom: 24px;
  line-height: 20px;
  color: #000000;
}

.font-paragraph-header {
  font-family: var(--gt-america-condensed-bold);
  font-size: 25px;
  margin-bottom: 20px;
  line-height: 31px;
  color: #000000;
}
.font-paragraph {
  font-family: var(--gt-america);
  font-size: 20px;
  margin-bottom: 0;
  line-height: 31px;
  color: #000000;
}

.font-list-title {
  font-family: var(--gt-america-compressed-bold);
  font-size: 60px;
  margin-bottom: 0;
  line-height: 65px;
  color: #000000;
}

.font-list-item {
  font-family: var(--gt-america);
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 22px;
  color: #000000;
}

.font-list-item-bold {
  font-family: var(--gt-america-bold);
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 22px;
  color: #000000;
}

.font-table-header {
  font-family: var(--gt-america-mono);
  font-size: 16px;
  margin-bottom: 24px;
  line-height: 24px;
  color: #000000;
}
.font-mono-16 {
  font-family: var(--gt-america-mono);
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 24px;
  color: #000000;
}
.font-alt-text {
  font-family: var(--gt-america-mono);
  font-size: 15px;
  margin-bottom: -15px;
  line-height: 20px;
}
.font-alt-text-bold {
  font-family: var(--gt-america-mono-bold);
  font-size: 15px;
  margin-bottom: -15px;
  line-height: 20px;
}

.font-download-link {
  font-family: var(--gt-america-mono);
  font-size: 16px;
  margin-bottom: -15px;
  line-height: 20px;
}

.font-button {
  font-family: var(--gt-america-mono);
  text-transform: capitalize;
  font-size: 20px;
  margin-bottom: 5px;
  line-height: 22px;
}

.font-contact-title {
  font-family: var(--gt-america-mono-bold);
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 24px;
}
.font-publication-title {
  font-family: var(--gt-america-condensed-bold);
  font-size: 25px;
  margin-bottom: 20px;
  line-height: 31px;
}

.font-article-title {
  font-family: var(--canela);
  font-size: 80px;
  margin-bottom: 5px;
  line-height: 95px;
}

.font-article-ingress {
  font-family: var(--canela-text);
  font-size: 26px;
  margin-bottom: 0px;
  line-height: 36px;
}
.font-article-quote {
  font-family: var(--canela-text);
  font-size: 50px;
  margin-bottom: 0px;
  line-height: 60px;
}

.font-article-author {
  font-family: var(--gt-america-mono);
  font-size: 15px;
  margin-bottom: 24px;
  line-height: 24px;
}

.font-article-author {
  font-family: var(--gt-america-mono);
  font-size: 15px;
  margin-bottom: 24px;
  line-height: 24px;
}

.font-news-title {
  font-family: var(--gt-america-condensed-bold);
  font-size: 80px;
  margin-bottom: 5px;
  line-height: 95px;
}
.font-news-ingress {
  font-family: var(--gt-america-condensed-bold);
  font-size: 26px;
  margin-bottom: 0;
  line-height: 36px;
}
.font-property-title {
  font-family: var(--gt-america-compressed-bold);
  text-transform: capitalize;
  font-size: 80px;
  margin-bottom: 5px;
  line-height: 100px;
}
.font-property-ingress {
  font-family: var(--canela-text);
  font-size: 26px;
  margin-bottom: 0;
  line-height: 36px;
}

.Canela-Regular-Web {
  font-family: 'Canela Web';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.CanelaText-Regular-Web {
  font-family: 'Canela Text Web';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.fontFamily {
  font-family: var(--fonts);
}
