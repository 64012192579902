.ProfilBildePanel {
  grid-area: ProfilBildePanel;
  display: grid;
  gap: 15px;
  grid-template-columns: 3fr 1fr;
  align-items: flex-start;

  @include breakpoint-below-tablet {
    grid-template-columns: 1fr;
    row-gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & img {
    object-fit: contain;
    width: 100%;
    height: 130px;
    min-width: 8em;

    @include breakpoint-below-tablet {
      width: 140px;
      height: 140px;
    }

    @include breakpoint-desktop-only {
      width: 177px;
      height: 177px;
    }
  }

  &-lastopp {
    h5 {
      margin-bottom: 24px;
    }

    p {
      margin-top: 0;
      margin-bottom: 24px;

      @include breakpoint-desktop-only {
        margin-top: 15px;
        margin-bottom: 7px;
      }
    }

    a {
      text-decoration: underline;
      color: blue;
      cursor: pointer;
    }
  }
}
