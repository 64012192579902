.EierDataBlockWithOverride {
  display: flex;
  flex-direction: column;
  background-color: #F6F7F8;
  padding: 2rem 2rem;
  margin: 2rem 0;
  border: 1px solid #dbdbdb;

  .EierLabel {
    margin-bottom: 1em;
  }

  .Org-data {
    [variant="paragraph-header"] {
      margin-bottom: 0;
    }
  }

  .Org-edit {
    margin-top: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4em;
  }

  @media (max-width: 768px) {
    .Org-edit {
      grid-template-columns: 1fr;
      grid-row-gap: 2em;
      grid-column-gap: 0;
    }
  }

  .Org-links {
    display: flex;

    a {
      margin-right: 2rem;
      margin-top: 1rem;
      white-space: break-spaces;
    }
  }
}
