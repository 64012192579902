.TilbudtObjekt {
  position: relative;
  text-decoration: none;
  display: grid;
  grid-template-columns: 1fr 4fr;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }

  &.green {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset -10px 0px 0px #5c9424;
  }

  &.orange {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset -10px 0px 0px #f9b100;
  }

  &.red {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset -10px 0px 0px #cf0202;
  }

  &--image-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 200px;
    box-sizing: border-box;

    img {
      object-fit: cover;
      flex: auto;
      max-height: 25.5em;
    }

    .tongue {
      width: 100%;
      height: 30px;
      background: #1d1d1d;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 900px) {
      max-width: 100%;

      img {
        aspect-ratio: 2/1;
      }
    }

    @include breakpoint-below-tablet{
      display: none;
    }

  }


  &--content{
    display: flex;
    flex-direction: column;

    &--top{
      padding-left: 20px;
      margin-top: 1em!important;
    }
    &--bottom{
      display: flex;

      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
      .info {
        padding: 20px;
        min-width: 17em;
        
        ul{
          list-style:none;
          padding: 0px;
        }
    
        li {
          margin-bottom: 10px;
        }
    
        li b {
          color: var(--primary-color);
        }
      }
      .status-tag{
        position: absolute;
        right: 1.5em;
        top: 1.5em;
        background-color: #88C9D0;
        border-radius: 30px;
        padding: 10px 15px;
        text-transform: uppercase;
    
        @include breakpoint-below-tablet{
          top: -8%;
          box-shadow: 2px 2px 4px 0px rgb(0 0 0 / 35%);
        }
      }
    
      .scores {
        width: 99%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(6em, 1fr));
        align-items: center;
        padding: 1em 2em;
        grid-gap: 20px;
        
        @media screen and (max-width: 900px) {
          grid-template-columns: repeat(auto-fill, minmax(5em, 1fr));
        }
    
        .scores-item{
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 7em;
        }
      }
    }
    
  }
  
}
