.ProsjektRedigerContainer {
  position: relative;

  &__prosjektnavn {
    color: #232e3a;
    font-family: "Open Sans", sans-serif;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 65px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__required {
    text-align: right;
    margin-bottom: 30px;
  }

  &__actions {
    display: flex;
    align-items: center;

    &--absolute {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__iconButtons {
    display: flex;

    & > * {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
