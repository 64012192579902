// BrukerSide gjenbruker stort sett CSS stiler fra ProfilSide
.BrukerSide {
  &__popup-buttons {
    display: flex;
    justify-content: flex-end;

    & button {
      margin-left: 20px;
    }
  }
}

.ProfilSide {
  &__info__buttons {
    grid-area: submitArea;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 15px;
    justify-items: flex-end;

    @include breakpoint-below-tablet {
      grid-template-columns: 1fr;
      justify-items: center;
      grid-row-gap: 15px;
    }
  }
}
