.UploadField {
  display: grid;
  grid-auto-columns: 1fr min-content;
  grid-auto-flow: column;
  row-gap: 15px;

  &.UploadFieldMultiple {
    column-gap: 15px;
  }

  &__link {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  &__label {
    flex: 1 1 0;
    height: var(--button-height);
    text-align: right;
    color: #888888;
    padding: 0 10px;
    border: 1px solid $color--input-border-gray;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__delete {
    display: flex;
    border: none;
    background: none;
    color: #888888;
    padding: 5px;

    &:hover {
      background: #aa0000;
      color: white;
    }

    &:disabled,
    &:disabled:hover {
      background: none;
      color: #aa0000;
    }
  }
}
