$black: #1d1d1d;
$grey: var(--button-disabled);
$accent: #bfe0e4;
.DropdownMenuTrigger {
  button {
    all: unset;
  }
}
.DropdownMenuContent,
.DropdownMenuSubContent {
  min-width: 220px;
  background-color: white;
  border-radius: 8px;
  padding: 18px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  font-size: 16px;
  color: $black;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
  user-select: none;
  outline: none;
  border: unset;
  background-color: unset;
  width: 100%;
  cursor: pointer;
}
.DropdownMenuSubTrigger[data-state='open'] {
  background-color: $accent;
  color: $black;
}
.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  color: $grey;
  pointer-events: none;
}

.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  background-color: $accent;
  color: $black;
}

.DropdownMenuLabel {
  font-size: 12px;
  line-height: 25px;
  color: #333;
}

.DropdownMenuSeparator {
  height: 1px;
  background-color: #d9d9d9;
  margin: 5px;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: white;
}

.IconButton {
  font-family: inherit;
  border-radius: 5px;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $black;
  background-color: white;
}

.IconButton:hover {
  background-color: $color--glassbla01;
}

.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve11);
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--mauve8);
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
