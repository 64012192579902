.ProsjektRedigerSide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  align-items: start;

  &__Beskrivelse {
    grid-column: span 2;
  }
}

.span-full {
  grid-column: span 2;
}
